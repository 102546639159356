import React from 'react';

import styled from '@emotion/styled';

import Badge from '../../../components/Badge/Badge';
import { PrimaryButton } from '../../../components/Button';
import Img from '../../../components/Img/Img';
import { ButtonLink } from '../../../components/Link/Link';
import TextCard from '../../../components/TextCard/TextCard';

const AboutUs = () => {
  return (
    <Container>
      <Img src="/static/img/female-nurse.png" />
      <TextSection>
        <Badge>About us</Badge>
        <TextCard title="Who Are We?">
          Working as a healthcare worker is daunting enough especially when you
          are working freelance. Aside from securing your next few hours of
          work, organising and scheduling your subsequent jobs should not be so
          difficult. We make it easy for employers to find locums and locums to
          find employers, helping you make the most of you limited time. <br />
          <br />
          We make it easy for you to find rewarding job placements, ensure and
          facilitate payment for your work hours and create a healthy job
          environment where job seekers and employers thrive.
          <br />
          <br /> We envision a work place future where healthcare workers can
          get well deserved fair and equal pay for their hours in service and
          also get creditable hours for their work experience, we are committed
          to making this reality and look forward to your partnership to make
          this possible as we empower you with the right tools and resources to
          manage your hours in locum practice.
        </TextCard>
        <ButtonLink to={'/'}>
          <PrimaryButton arrowIcon className="big fullWidth">
            Learn More
          </PrimaryButton>
        </ButtonLink>
      </TextSection>
    </Container>
  );
};

export default AboutUs;

// Styled
const Container = styled.section`
  display: flex;
  gap: 2rem;
  margin: 9.37rem 8.25rem 1rem;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    margin: 0;
    padding: 1.5rem;
    flex-direction: column-reverse;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
