import React from 'react';

const TelegramIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Background Circle */}
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#2857D1"
      />

      {/* Telegram Logo */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4501 10.2499L19.9334 21.0666C19.7334 21.8499 19.2834 22.0499 18.6001 21.6666L14.9334 18.8499L13.2334 20.4833C13.0168 20.6999 12.8334 20.8833 12.4168 20.8833L12.6834 17.1499L19.4501 11.5666C19.7501 11.3333 19.4001 11.1999 19.0001 11.4333L10.9501 16.1999L7.33341 15.0499C6.56675 14.7999 6.55008 14.2833 7.48341 13.8833L21.6168 8.04992C22.2668 7.79992 22.8501 8.21659 22.4501 10.2499Z"
        fill="#2857D1"
      />
    </svg>
  );
};

export default TelegramIcon;
