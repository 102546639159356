import React from 'react';

import styled from '@emotion/styled';

import { FormSubmitButton } from '../../components/Button/FormSubmitButton';
import { FormComponent } from '../../components/Form/FormComponent';
import {
  TextAreaInputField,
  TextInputField,
} from '../../components/Form/FormField';
import Page from '../../components/Page/Page';
import {
  ContactUsDataType,
  contactUsInitialValues,
  ContactUsSchema,
} from '../../models/contactUs/contactUs.model';

const ContactUs = () => {
  const onSubmit = (values: ContactUsDataType) => {
    console.log(values);
    const { firstName, lastName, email, message } = values;

    // Define recipient email
    const recipientEmail = 'Info@locumspace.co'; // Change if needed

    // Encode subject and body
    const subject = encodeURIComponent(
      `Contact Request from ${firstName} ${lastName}`
    );
    const body = encodeURIComponent(
      `Hi,\n\n${firstName} ${lastName} wrote:\n\n${message}\n\nReply to: ${email}`
    );

    // Construct mailto URL
    const mailtoLink = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;

    // Open email client
    window.location.href = mailtoLink;
  };

  return (
    <Page title="LocumSpace | FAQs">
      <Container>
        <TitleArea>
          <Title>Connect with us</Title>
          <P>
            We value your connection and are committed to providing exceptional
            support and guidance throughout your locum journey.
          </P>
        </TitleArea>
        <FormArea>
          <FormComponent
            initialValues={contactUsInitialValues}
            schema={ContactUsSchema}
            onSubmit={onSubmit}
            className="contact"
          >
            <TextInputField
              label="First Name"
              name="firstName"
              type="text"
              placeholder="Enter your email first name "
              className="contact"
            />
            <TextInputField
              label="Last Name"
              name="lastName"
              type="text"
              placeholder="Enter your last name "
              className="contact"
            />
            <TextInputField
              label="Email Address"
              name="email"
              type="email"
              placeholder="Enter your email address "
              className="spanTwo contact"
            />
            <TextAreaInputField
              label="Message"
              name="message"
              type="text"
              placeholder="Message"
              className="spanTwo contact"
            />
            <FormSubmitButton type="submit" className="contact spanTwo">
              Send
            </FormSubmitButton>
            <AltText>
              You can also send a message to us: <span>Info@locumspace.co</span>
            </AltText>
          </FormComponent>
        </FormArea>
      </Container>
    </Page>
  );
};

export default ContactUs;

// styles
const FormArea = styled.div`
  padding: 4.69rem 2.62rem;
  margin-top: 5.2rem;
  /* border: 2px solid red; */
  width: 100%;

  @media (max-width: 480px) {
    padding: 0rem;
    margin-top: 2.2rem;
    margin-bottom: 3rem;
  }

  & > * {
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      /* border: 2px solid red; */
    }
  }
`;

const Container = styled.div`
  display: flex;
  margin: 6.25rem 9.94rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    gap: 1rem;
    /* border: 2px solid red; */
    margin: 0;
    padding: 1.5rem;
  }
`;

const TitleArea = styled.div`
  width: 40rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    width: fit-content;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.palette.blackBlack2};

  @media (max-width: 480px) {
    font-size: 1.5rem;
    font-weight: 400;
  }
`;

const P = styled.p`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.blackBlack3};
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.125rem;
  }
`;

const AltText = styled.div`
  & > span {
    color: ${({ theme }) => theme.palette.mainBlue};
  }
`;
