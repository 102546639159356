import React from 'react';

import styled from '@emotion/styled';

import SquarePlanCard from '../../../components/Card/SquarePlanCard';
import TitledSection from '../../../components/TitledSection/TitledSection';

const SolutionWeProvide = () => {
  return (
    <TitledSection title="The Solutions we provide">
      <CardArea>
        <SquarePlanCard
          title="Give Yourself Peace of Mind"
          description="Create and manage your rota in advance, fill available shifts anytime with regulatory compliant and verified healthcare personnels."
        />
        <SquarePlanCard
          title="Ensure an Engaged, Productive Workforce."
          description="Completely eliminate paper with our scheduling tool. Manage active shifts effectively and keep a seamless records of your active and pending shift."
        />
        <SquarePlanCard
          title="Reduce Admin and focus on patient care"
          description="Reduce admin by managing applications by volume effectively. Build and grow an engaged bank of direct and agency contacts, guaranteeing 100% compliance."
        />
        <SquarePlanCard
          title="Better informed, data-driven decisions"
          description="Our reporting module allows you to view relevant application stats and make better data-driven decisions."
        />
        <SquarePlanCard
          title="Connect with compliant staff through our marketplace"
          description="Our Marketplace solution allows you to access thousands of healthcare workers looking for temporary and permanent work eliminating staff shortages and potential financial penalties."
        />
        <SquarePlanCard
          showFooter
          title="Make accurate payments on time, every time"
          description="Our streamlined payments solution allows you to process payments and expenses with the click of a button."
        />
      </CardArea>
    </TitledSection>
  );
};

export default SolutionWeProvide;

// styles
const CardArea = styled.section`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    margin: 0;
    display: flex;
    flex-direction: column;
  }
`;
