import React from 'react';

import styled from '@emotion/styled';

import ReviewCard from '../../../components/Card/ReviewCard';

const ClientSuccessStories = () => {
  return (
    <Container>
      <TitleArea>
        <Title>Client success stories</Title>
        <P>See for your self what people say about us!</P>
      </TitleArea>
      <CardArea>
        <ReviewCard
          title="Wale James"
          description="I used Locate a Locum for the first time to find optometry shifts and I was delighted with the service, the app is so handy. It allows me to track all my shifts! "
        />
        {/* <ReviewCard
          title="Wale James"
          description="I used Locate a Locum for the first time to find optometry shifts and I was delighted with the service, the app is so handy. It allows me to track all my shifts! "
        />
        <ReviewCard
          title="Wale James"
          description="I used Locate a Locum for the first time to find optometry shifts and I was delighted with the service, the app is so handy. It allows me to track all my shifts! "
        />
        <ReviewCard
          title="Wale James"
          description="I used Locate a Locum for the first time to find optometry shifts and I was delighted with the service, the app is so handy. It allows me to track all my shifts! "
        />
        <ReviewCard
          title="Wale James"
          description="I used Locate a Locum for the first time to find optometry shifts and I was delighted with the service, the app is so handy. It allows me to track all my shifts! "
        />
        <ReviewCard
          title="Wale James"
          description="I used Locate a Locum for the first time to find optometry shifts and I was delighted with the service, the app is so handy. It allows me to track all my shifts! "
        /> */}
      </CardArea>
    </Container>
  );
};

export default ClientSuccessStories;

// styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.palette.blackBlack2};

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const P = styled.p`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.blackBlack3};
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.125rem;
  }
`;

const CardArea = styled.section`
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // gap: 1.25rem;
  // /* border: 2px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4.5rem 6.25rem;

  @media (max-width: 480px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
`;
